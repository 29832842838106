import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Prismic from 'prismic-javascript';
import { Col, Input, Row, Select } from 'antd';
import { withTrans } from '../i18n/withTrans';
import AppLayout from '../components/AppLayout';
import SEO from '../components/seo';

const apiEndpoint = 'https://mattines.cdn.prismic.io/api/v2';

const RecipesPage = () => {
	const { t } = useTranslation();
	const [ search, setSearch ] = useState('');
	const [ recipe, setRecipe ] = useState({ title: '', legume_image: '' });
	const [ recipes, setRecipes ] = useState([]);

	const data = useStaticQuery(graphql`
	    query {
	      imageRecipes: file(relativePath: { eq: "recipes.jpg" }) {
	        childImageSharp {
	          fluid(maxWidth: 1920) {
	            ...GatsbyImageSharpFluid_withWebp
	          }
	        }
	      }
	    }
	`);

	useEffect(() => {
		Prismic.getApi(apiEndpoint).then(
			api => api.getByID('X4b2uhEAACcAIomG', { lang : 'fr-ch' })
		).then(response => {
			setRecipe({
				title: response.data.title,
				legume_image: response.data.image_legume
			});
			setRecipes(response.data.recipes);
		});
	}, []);

	return (
		<AppLayout>
			<SEO title="Recettes" />

			<div className="hero-image">
				<Img fluid={data.imageRecipes.childImageSharp.fluid} />
			</div>

			<div>
				<div className="page-section">
					<div className="section-title">
						<h2>
							{recipe.title.length ? recipe.title[0].text : ''}
							{recipe.legume_image.url ? (
								<img src={recipe.legume_image.url} alt={recipe.title.length ? recipe.title[0].text : ''} />
							) : null}
						</h2>
						<div className="section-title-line" />
					</div>

					<div className="section-content">
						<div className="section-content-text">
							<div style={{ marginBottom: 40, textAlign: 'center' }}>
								<Input.Search
									size="large"
									placeholder={t('SEARCH_RECIPE')}
									onChange={e => setSearch(e.target.value)}
									style={{ maxWidth: 320 }}
								/>
							</div>
							<Row gutter={32} justify="center">
								<Col xs={24} md={8} lg={6} xxl={4}>
									<Select style={{ width: '100%' }}>
										<Select.Option value="test">Test</Select.Option>
									</Select>
								</Col>
								<Col xs={24} md={8} lg={6} xxl={4}>
									<Select style={{ width: '100%' }}>
										<Select.Option value="test">Test</Select.Option>
									</Select>
								</Col>
								<Col xs={24} md={8} lg={6} xxl={4}>
									<Select style={{ width: '100%' }}>
										<Select.Option value="test">Test</Select.Option>
									</Select>
								</Col>
							</Row>
						</div>
					</div>

					<div className="section-list">

					</div>
				</div>
			</div>
		</AppLayout>
	);
};

export default withTrans(RecipesPage);
